<app-menu>
  <div class="km-report-container" layout-align="center center">
    <div>
      <h1 class="km-report-container__title">Rapport kilométrique</h1>
      <form (ngSubmit)="onSubmit(fm)" #fm="ngForm">
        <div class="">
          <div class="km-report-cta-container">
            <button class="km-report-cta" type="submit" [disabled]="!fm.form.valid">Generate Report
            </button>
          </div>

          <div class="km-report-section-container">
            <h3 class="">Année</h3>
            <mat-button-toggle-group class="km-report-section-container__years" [(ngModel)]="selectedYear" name="year"
                                     required=true>
              <mat-button-toggle *ngFor="let year of years" class="km-report-section-container__years__item"
                                 value="{{year.value}}">{{year.value}}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="km-report-section-container">
            <h3 class="item">Mois</h3>
            <mat-button-toggle-group class="km-report-section-container__months" [(ngModel)]="selectedMonth"
                                     name="month" required=true>
              <mat-button-toggle *ngFor="let month of months"
                                 class="km-report-section-container__months__item"
                                 value="{{month.value}}">{{month.name }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="km-report-section-container" style="display: block">
            <mat-button-toggle-group class="km-report-section-container__users" [(ngModel)]="selectedEducateur"
                                     name="educateur" required="true">
              <div class="km-report-section-container__users-container">
                <ng-container *ngFor="let br of brs">
                  <div class="km-report-section-container__users__user-container">
                    <h4>{{br}}</h4>
                    <ng-container *ngFor="let educateur of this.getEducateurByBr(br)">
                      <mat-button-toggle  value="{{educateur.PERSONNE_ID}}"
                                         class="km-report-section-container__months__item">{{educateur.personne.PRENOM}}</mat-button-toggle>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </mat-button-toggle-group>
          </div>


        </div>
      </form>
    </div>

  </div>
</app-menu>

