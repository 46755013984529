import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgForm} from "@angular/forms";
import {Config} from "../models/ConfigModel";
import {ConfigLoaderService} from "../services/config-loader.service";
import {Router} from "@angular/router";
@Component({
  selector: 'app-generate-report-km',
  templateUrl: './generate-report-km.component.html',
  styleUrls: ['./generate-report-km.component.scss']
})
export class GenerateReportKmComponent implements OnInit {
  private config: Config;
  educateurs;
  selectedEducateur: string;
  selectedMonth: number;
  selectedYear: number;
  months = [
    {name: "Janvier", value: 1},
    {name: "Février", value: 2},
    {name: "Mars", value: 3},
    {name: "Avril", value: 4},
    {name: "Mai", value: 5},
    {name: "Juin", value: 6},
    {name: "Juillet", value: 7},
    {name: "Août", value: 8},
    {name: "Septembre", value: 9},
    {name: "Octobre", value: 10},
    {name: "Novembre", value: 11},
    {name: "Décembre", value: 12},
  ];
  years = [
    {value: 2021},
    {value: 2022},
    {value: 2023},
    {value: 2024}
  ];
  brs=[];

  constructor(private http: HttpClient,configLoader: ConfigLoaderService,private route: Router) {
    this.config = configLoader.getConfigData();
  }

  ngOnInit(): void {
    this.fetchEducateurs();
  }

  onSubmit(_form: NgForm) {
    this.generateReport();
  }
  public fetchEducateurs(): any {
    this.http.get<any>(this.config.host+'/api/getEducateursActif').subscribe(
      response => {
        this.educateurs = response;
        for (let educateur of this.educateurs)
        {
          if(!this.brs.includes(educateur.BR))
          {
            this.brs.push(educateur.BR);
          }
        }
      }
    )

  }

  private generateReport() {
    location.href = this.config.host+'/api/report/km/' + this.selectedEducateur + '/' + this.selectedMonth + '/' + this.selectedYear;
  }

  public getEducateurByBr(br)
  {
      return this.educateurs.filter(educateur=>educateur.BR==br);

  }

}
