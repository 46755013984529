import {Injectable} from '@angular/core';
import {ConfigLoaderService} from "./config-loader.service";
import {Config} from "../models/ConfigModel";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from '@angular/material/snack-bar';
import {Beneficiary} from "../../model/Beneficiary";
import _ from "lodash";
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private config: Config;

  constructor(configLoader: ConfigLoaderService, private http: HttpClient, private _snackBar: MatSnackBar) {
    this.config = configLoader.getConfigData();
  }

  public generateAndDownloadPrestationInvoice(beneficiary: Beneficiary, year: number, month: number, isBap: boolean) {
    let url;
    if (isBap) {
      url = this.config.host + '/api/invoice/bap/' + beneficiary.contactId + '/' + beneficiary.beneficiaryId + '/' + year + '/' + month
    } else {
      url = this.config.host + '/api/invoice/repit/' + beneficiary.contactId + '/' + beneficiary.beneficiaryId + '/' + year + '/' + month
    }
    let fileName = this.getNameOfInvoice(beneficiary, month, year, isBap);
    return this.http.get<any>(url).subscribe(
      (data) => {
        let blob = new Blob([data]);
        saveAs(blob, fileName);
      },
      e => {
        this.openSnackBar('Erreur lors de la tentative de génération de la facture', "close");
      }
    );
  }

  public fetchBeneficiairesByMonth(month: number, year: number): any {
    return this.http.get<any>(this.config.host + '/api/invoice/beneficiariesToInvoice/' + month + '/' + year).toPromise();

  }

  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  private convertMonthAsString(month: number) {
    let monthAsString = '0' + month.toString();
    return monthAsString.slice(-2);
  }

  getNameOfInvoice(beneficiary: Beneficiary, month: number, year: number, isBap: boolean) {
    let invoiceName = '';
    let monthAsString = this.convertMonthAsString(month);
    if (isBap) {
      invoiceName = 'BAP-';
    }
    invoiceName = invoiceName + _.toUpper(beneficiary.lastName) + ' ' + _.startCase(_.toLower(beneficiary.firstName)) + ' - ' + monthAsString + '.' + year + '.pdf';
    return invoiceName;
  }
}
