<app-menu>
  <div class="generate-facture">
    <h1>Génération de facture</h1>
    <div class="generate-facture__periode-row">
      <div class="generate-facture__periode-row__select-year">
        <mat-form-field appearance="fill">
          <mat-label>Année</mat-label>
          <mat-select [(ngModel)]="selectedYear" (ngModelChange)="updateBeneficiaries(selectedMonth,selectedYear)">
            <mat-option>*-*-*-*-</mat-option>
            <mat-option *ngFor="let year of years" [value]="year.value">{{year.value}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="generate-facture__periode-row__select-month">
        <mat-form-field appearance="fill">
          <mat-label>Mois</mat-label>
          <mat-select [(ngModel)]="selectedMonth" (ngModelChange)="updateBeneficiaries(selectedMonth,selectedYear)">
            <mat-option>*-*-*-*-</mat-option>
            <mat-option *ngFor="let month of months" [value]="month.value">{{month.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="generate-facture__cta-main-row">
      <button class="btn generate-facture__cta-main-row__button">
        <mat-icon style="margin-right: 8px;">attach_money</mat-icon>
        Tout générer
      </button>
      <button class="btn generate-facture__cta-main-row__button">
        <mat-icon style="margin-right: 8px;">email</mat-icon>
        Tout envoyer
      </button>
      <button class="btn generate-facture__cta-main-row__button" >
        <mat-icon style="margin-right: 8px;">picture_as_pdf</mat-icon>
        Tout télécharger
      </button>
    </div>
    <div class="generate-facture__beneficiaires-container">
      <app-benefeciary-card *ngFor="let beneficiary of this.repitBeneficiaires"

                            [isBap]="false" [beneficiary]="beneficiary" [month]="selectedMonth" [year]="selectedYear"
      ></app-benefeciary-card>
      <app-benefeciary-card *ngFor="let beneficiary of this.bapBeneficiaires"

                            [isBap]="true" [beneficiary]="beneficiary" [month]="selectedMonth" [year]="selectedYear"
      ></app-benefeciary-card>
    </div>
  </div>


</app-menu>
