<app-menu>
  <div class="user-main-interface">
    <h1 style="margin-bottom: 1.5rem;">Bonjour, James</h1>
    <h2 style="margin-bottom: .5rem">Notifications</h2>
    <div class="user-main-interface__container">
      <div class="user-main-interface__container__group">
        <mat-card class="example-card">
          <mat-card-subtitle>Wallopoly</mat-card-subtitle>
          <mat-card-title>Prestations non validés</mat-card-title>
          <mat-card-content>
            <p>Les prestations suivantes ne sont pas encore validées</p>

          </mat-card-content>
          <mat-divider inset></mat-divider>
          <mat-card-actions>
            <button class="btn" disabled>Voir les prestations</button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <mat-card-subtitle>Admin</mat-card-subtitle>
          <mat-card-title>Formatage des adresses</mat-card-title>
          <mat-card-content>
            <p>{{this.countOfAddressesWrongFormated}} adresses n'ont pas pu être traitées par le système</p>
          </mat-card-content>
        </mat-card>
      </div>
      <mat-card style="justify-self: end">
        <h5>ACTIONS UTILES</h5>
        <div class="user-main-interface__container__usefull-actions">
          <button class="btn btn--full-width" [routerLink]="['/update-password']" disabled>Modifier mon mot de passe</button>
          <button class="btn btn--full-width" [routerLink]="['/add-user']" disabled>Ajouter utilisateur</button>
          <button class="btn btn--full-width" [routerLink]="['/admin']">Admin</button>
        </div>
      </mat-card>
    </div>
    <mat-spinner class="spinner"
                 mode="indeterminate"
                 *ngIf="requesting"
                 diameter="40"></mat-spinner>
    <mat-accordion class="example-headers-align" multi *ngIf="!requesting">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Déplacement avec bénéficiaire
          </mat-panel-title>
          <mat-panel-description>
            {{this.getTotalOfElementsToDisplay(wrongEncodedAddresses['deplacementsPrestation'])}}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div *ngFor="let deplacement of this.wrongEncodedAddresses['deplacementsPrestation']">
          <p><strong>Date de prestation:</strong>{{deplacement.wallopoly_prestation.DATE.substring(0, 10)}}
          </p>
          <p>
            <strong>Beneficiaire:</strong> {{deplacement.wallopoly_prestation.beneficiaire.personne.NOM + '-' + deplacement.wallopoly_prestation.beneficiaire.personne.PRENOM}}
          </p>
          <p *ngIf="deplacement.ref_adresse == null"><strong>Adresse mal encodée:</strong>{{deplacement.ADRESSE1}}
          </p>
          <p *ngIf="deplacement.ref_adresse_2 == null && deplacement.ADRESSE2 != ''"><strong>Adresse mal
            encodée:</strong>{{deplacement.ADRESSE2}}</p>
          <p *ngIf="deplacement.ref_adresse3 == null && deplacement.ADRESSE3 != ''"><strong>Adresse mal
            encodée:</strong>{{deplacement.ADRESSE3}}</p>
          <mat-divider></mat-divider>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Adresse de prestation
          </mat-panel-title>
          <mat-panel-description>
            {{this.getTotalOfElementsToDisplay(wrongEncodedAddresses['wallopolyPrestation'])}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngFor="let deplacement of wrongEncodedAddresses['wallopolyPrestation']">
          <p><strong>Date du deplacement:</strong>
            {{deplacement.DATE.substring(0, 10)}}</p>
          <p><strong>Adresse mal encodée:</strong>
            {{deplacement.ADRESSE + ',' + deplacement.CP + ',' + deplacement.LOCALITE}}</p>
        </div>
        <mat-divider></mat-divider>


      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Déplacement R.A
          </mat-panel-title>
          <mat-panel-description>
            {{this.getTotalOfElementsToDisplay(wrongEncodedAddresses['wallopolyPersonnelDeplacement'])}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngFor="let deplacement of wrongEncodedAddresses['wallopolyPersonnelDeplacement']">
          <p><strong>Date de déplacement:</strong>
            {{deplacement.wallopoly_personnel_ra.DATE.substring(0, 10)}}</p>
          <p *ngIf="deplacement.ref_adresse_1 ==null">Adresse mal encodée:{{deplacement.ADRESSE_DEPART}}</p>
          <p *ngIf="deplacement.ref_adresse_2 ==null">Adresse mal encodée:{{deplacement.ADRESSE_ARRIVEE}}</p>
        </div>
        <mat-divider></mat-divider>


      </mat-expansion-panel>
    </mat-accordion>
  </div>
</app-menu>
